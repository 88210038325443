import { createContext, useContext, useMemo, useState, useEffect } from "react";

const initialState = {
  isActive: false,
  setActive: () => {},
  config: {
	'button_color': '',
	'button_link': '',
	'button_text': '',
	'discount_color': '',
	'discount_description': '',
	'discount_text': '',
	'start_timer': 0,
	'end_timer': 0,
  },
  setConfig: () => {},
};

const MarketContext = createContext(initialState);

export const MarketProvider = ({ children }) => {

	const [isActive, setActive] = useState(initialState.isActive);
	const [config, setConfig] = useState(initialState.config);

  useEffect(() => {
	const now = Date.now() / 1000;
  
	if (config.start_timer && config.end_timer) {
	  if (now >= config.start_timer && now <= config.end_timer) {
		setActive(true);
	  } else {
		setActive(false);
	  }
	}
  }, [config, setActive]);

  const value = useMemo(() => ({
	isActive,
	setActive,
	config,
	setConfig,
  }), [isActive, config]);

  return <MarketContext.Provider value={value}>{children}</MarketContext.Provider>;
};

export const useMarketContext = () => {
  return useContext(MarketContext);
};
